import React, { Component } from 'react';
import axios from 'axios';
import {graphql, navigate} from 'gatsby';
import Grid from "@material-ui/core/Grid/Grid";

import { isLoggedIn, getUser } from '../../services/auth';
import { isBrowser } from '../../helpers';

import Layout from '../../components/layout';
import AccountHeader from "../../components/Account/AccountHeader";
import AccountNavWrap from "../../components/Account/AccountNavWrap";

import { CoreHeadingBlock } from "../../components/blocks/CoreHeadingBlock";
import { CoreParagraphBlock } from "../../components/blocks/CoreParagraphBlock";
import { CoreButtonBlock } from "../../components/blocks/CoreButtonBlock";

const querySalesOffers = group => {
    return {
      query: `
      {
        group(id: "${group}", idType: SLUG) {
            id
            groupFields {
              salesOffers {
                mediaItemUrl
              }
            }
        }
      }
    `}
  };

class SalesOffers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      salesOffers: null,
      loading: true
    }

    isBrowser && this.handleAuthentication();
  }

  async handleAuthentication() {
    const response = await isLoggedIn();

    if (response.data.errors) {
      navigate('/account/login');
    } else {
      this.setState({
        authenticated: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.salesOffers && this.state.authenticated) {
      this.fetchSalesOffers();
    }
  }
  
  fetchSalesOffers() {
    axios.post(`${process.env.GATSBY_ADMIN_URL}graphql`, querySalesOffers(getUser().group), {
      headers: { 'Content-Type': 'application/json'}
    })
    .then(res => res.data.data)
    .then(data => {
      this.setState({
        salesOffers: data.group.groupFields.salesOffers?.mediaItemUrl,
        loading: false
      })
    })
  }

  render() {
    const { firstName, lastName, email, login } = getUser();
    const { loading } = this.state;

    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: '/account/offers/'
        }}
        path={'a'}
        cta={'a'}
        themeOptions={this.props.data.wp.themeOptions}
      >
        {!login && <p>Redirecting...</p>}

        {login && <AccountHeader
          name={`${firstName} ${lastName}`}
          email={`${email}`}
        />}

        {login && <Grid container spacing={0} direction="row">
            <Grid item xs={12} sm={3}>
              <AccountNavWrap />
            </Grid>
            <Grid item xs={12} sm={9}>
                <div className="page-content" style={{
                  borderLeft: '4px solid #19365B',
                  marginBottom: 120,
                  padding: 40,
                  boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                }}>
                  {loading && <p>Loading offers...</p>}
                  {!this.state.salesOffers && !loading && <p>No current updates and sales offers available</p>}
                  {this.state.salesOffers && !loading && <div style={{
                    maxWidth: 720,
                    margin: '0 auto',
                  }}>
                    <CoreHeadingBlock 
                      attributes={{
                          align: "",
                          anchor: "",
                          className: "",
                          content: "Updates &amp; Sales Offers",
                          level: 3,
                          textColor: "",
                          backgroundColor: "",
                          __typename: "WpCoreHeadingBlockAttributes",
                      }}
                      innerBlocks={[]} 
                    />
                    <CoreHeadingBlock 
                      attributes={{
                          align: "",
                          anchor: "",
                          className: "",
                          content: "The latest updates and sales offers are here",
                          level: 5,
                          textColor: "",
                          backgroundColor: "",
                          __typename: "WpCoreHeadingBlockAttributes",
                      }}
                      innerBlocks={[]} 
                    />
                    <br/>
                    <br/>
                    <CoreParagraphBlock
                      attributes={{
                        align: "",
                        className: "",
                        content: "These may change from time to time, so make sure you check back regularly to see the latest.",
                        textColor: "",
                        backgroundColor: "",
                      }}
                    />
                    <br/>
                    <br/>
                    <CoreButtonBlock
                      attributes={{
                        align: "",
                        backgroundColor: "primary",
                        borderRadius: 0,
                        className: "",
                        gradient: "",
                        linkTarget: "_blank",
                        placeholder: "",
                        rel: "",
                        text: "Download offers",
                        textColor: "foreground_primary",
                        title: "",
                        url: this.state.salesOffers,
                        __typename: "WpCoreButtonBlockAttributes",
                      }}
                    />
                  </div>}
                </div>
            </Grid>
        </Grid>}
      </Layout>
    )

  }
}

export const query = graphql`
  query {
    wpPage(uri: {eq: "/"}) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default SalesOffers;